import Vue from 'vue';
import Vuetify from 'vuetify/lib';
// import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
    // theme: {
    //     themes: {
    //       light: {
    //         primary: colors.blue.darken3, // "rgb(107, 182, 86)", // #E53935
    //         secondary: colors.red.lighten4, // #FFCDD2
    //         accent: colors.green.base, // #3F51B5
    //       },
    //     },
    // }
});
