<template>
  <v-app id="inspire">
    <v-main class="grey lighten-3">
      <div style="background-color: #231f20; display:flex">
        <img src="@/assets/JDF-logo.png" class="ml-auto mr-auto" style="height:71px; margin: 20px"/>
    </div>
    <v-container class="pa-0">
        <div style="display:flex; padding: 20px">
          <div style="margin: auto"><h1>Registration Form</h1></div>
        </div>
        <router-view>Route appears here</router-view>
      </v-container>
    </v-main>
    <v-footer
      color="rgb(128,128,128)"
    >
      <span class="white--text">&copy; {{ new Date().getFullYear() }} App developed by Code Poet (Pty) Ltd</span>
      <span class="white--text ml-auto mr-auto">{{ mode }} {{ buildDate }}</span>
    </v-footer>
  </v-app>
</template>

<script>
  export default {
    data: () => ({
        drawer: false,
        links: [
      ],     
      mode: '',
      buildDate: ''
    }),
    mounted () {  
        document.title = "Jeff Daniels Foundation Registration | You are enough."
      this.mode = process.env.VUE_APP_BUILD_MODE
      this.buildDate = document.documentElement.dataset.buildTimestampUtc
    },
    components: {
        // LoginStatus: () => import('./components/LoginStatus'),
        // SyncStatus: () => import('./components/SyncStatus'),
    },
  }
</script>
