import Vue from 'vue'
import Router from 'vue-router'
import user_context from './user_context'

Vue.use(Router)

const buildMode = process.env.VUE_APP_BUILD_MODE

let router = new Router({
  mode: 'hash',
  //base: 'app',
  //mode: 'history',
  routes: [
    {
      name: 'Registration',
      path: '/register',
      component: () => import('@/views/Register'),
    },
    {
      path: '*',
      redirect: '/register'
    },
  ],
})

router.beforeEach((to, from, next) => {
  console.log('to: ' + to.name)
  if (buildMode != "development" ) {
    if (to.name !== 'Login' && !user_context.current.loggedIn)
    {
      console.log('user login prompted.')
      //next({ name: 'Login', query: { redirect_to: to.path } })
      next()
    }
    else
      next()
  }
  else
    next()
})

export default router