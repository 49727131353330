import axios from 'axios'

class UserContext
{
  
  constructor() {
    this.tokenRefreshOffset = -10 * 60000
    this.token = ''
    this.tokenData = ''
    this.displayName = ''
    this.tokenExpires = undefined
    this.loggedIn = false
    this.refreshIsPending = false
    if (localStorage.loginDeets4) {
      this.respondToToken(localStorage.loginDeets4)
    }
    if (document.cookie) {
      let cookieObj = this.parseCookie(document.cookie)
      this.respondToRefreshToken(cookieObj.refresh)
    }
  }
  setLoginTokens (token, refresh_token) {
    this.respondToToken(token)
    this.respondToRefreshToken(refresh_token)
  }
  respondToToken(token) {
    if (token) {
      this.tokenData = JSON.parse(atob(token.split('.')[1]))
      this.tokenExpires = new Date(this.tokenData.exp * 1000)
      if (this.tokenExpires > new Date()) {
        localStorage.loginDeets4 = token
        this.token = token
        this.username = this.tokenData.username
        this.displayName = this.username
        this.loggedIn = true
      } else {
        this.setLogout()
      }
    } else {
      this.setLogout()
    }
  }
  respondToRefreshToken(refresh_token) {
    if (refresh_token) {
      document.cookie = "refresh=" + refresh_token + ";SameSite=strict; max-age=2628000" //1 month
      this.queueTokenRefresh(this.tokenExpires - new Date() + this.tokenRefreshOffset)
    }
  }
  
  queueTokenRefresh(timeout) {
    if (!this.refreshIsPending) {
      setTimeout(this.refreshToken.bind(this), timeout);
      this.refreshIsPending = true
    }
  }
  refreshToken() {
    this.refreshIsPending = false //TODO: retry a few times??
    let cookieObj = this.parseCookie(document.cookie)
    if (cookieObj.refresh) {
      console.log('refresing token')
      if (this.tokenExpires < new Date())
          this.token = ''
      return axios.post(process.env.VUE_APP_API_BASE_URL + '/connect/refresh', 
        { refresh_token: cookieObj.refresh },
        {
          headers: { 
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json"

          }
        }
      )
      .then((res) => {
        if (res.status == 200) {
          this.setLoginTokens(res.data.access_token, res.data.refresh_token)
          console.log('token refreshed')
          Promise.resolve(true)
        } else {
          console.log('token not refreshed')
          Promise.resolve(false)  
        }
      })
      .catch((err) => {
        console.log('token refresh error ' + err)
        Promise.resolve(false)
      })
    }
    else {
      return Promise.resolve()
    }
  }
  parseCookie(cookie) {
    //https://gist.github.com/rendro/525bbbf85e84fa9042c2#gistcomment-2784930
    return Object.fromEntries(cookie.split('; ').map(x => x.split('=')))
  }
  setLogout() {
    this.token = ''
    this.displayName = ''
    delete(localStorage.loginDeets4)
    document.cookie = "refresh=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    this.loggedIn = false
  }
}

const current = new UserContext()

export default { UserContext, current}
