import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from './router'
import user_context from './user_context'
import { VueReCaptcha}  from 'vue-recaptcha-v3'
import '@mdi/font/css/materialdesignicons.css'

 
Vue.use(VueAxios, axios)
Vue.use(require('vue-moment'))
Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_KEY })

Vue.config.productionTip = false

String.prototype.properCase = function(value) {
  value = this
  return value[0].toUpperCase() + value.substring(1)
}

axios.interceptors.request.use(
  function (config) {
    if ((!config.headers.Authorization) && user_context.current.token) {
      config.headers.Authorization = "Bearer " + user_context.current.token
    }
    return config;
  }
);

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  }, 
function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (error.response && error.response.status == 401) {
      //router.push( { name: 'Login' })
      console.log(' 401 ' + error.response.config.url)
      let history = router.history
      if (history.current.path != '/login') //sometimes multiple subsequent requests fail in 401
        router.replace({ name: 'Login', query: { redirect_to: history.current.path } })
  }
  else {
    return Promise.reject(error);
  }
});

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
